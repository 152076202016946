import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {BlindtestService} from '../blindtest/blindtest.service';
import {GameService} from '../game/game.service';
import {ReviewService} from '../review/review.service';
import {ScoreService} from '../score/score.service';
import {StatsService} from '../stats/stats.service';
import {HttpClient} from '@angular/common/http';
import {ErrorHandler} from '../../handler/error-handler';
import {LoggerService} from '../logger/logger.service';
import {OrganizationService} from '../organization/organization.service';
import {GameControls} from '@frogconnexion/blinding-common';

@Injectable()
export class GameControlsService {
  private currentGameStateObservable: Observable<GameControls>;
  private currentGameState: GameControls;
  private organization: string;

  constructor(private organizationService: OrganizationService,
              private blindtestService: BlindtestService,
              private gameService: GameService,
              private scoreService: ScoreService,
              private reviewService: ReviewService,
              private statsService: StatsService,
              private http: HttpClient,
              private logger: LoggerService,
              private errorHandler: ErrorHandler) {

    this.organizationService.organizationTag().subscribe(o => {
      this.organization = o;
    });
    // Current Game State observable
    this.currentGameStateObservable = this.gameService.currentGameControls();
    this.currentGameStateObservable.subscribe(g => {
      // Replay all game-specific observables
      this.currentGameState = g;
    });

  }

  // Public Async methods

  startSet(index: number): Observable<any> {
    if (!this.currentGameState) {
      throw new Error('Cannot start set: No game is currently set.');
    }
    if (index < 0 || index + 1 > this.currentGameState.blindtestControl.setCount) {
      throw new Error('Wrong index value. Must be between 0 and ' + (this.currentGameState.blindtestControl.setCount - 1));
    }
    // Set status of target set to OnGoing
    return this.http.post<void>(`/admin/org/${this.organization}/game/set/${index}/start`, null)
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  lockSong(): Observable<any> {
    if (!this.currentGameState) {
      throw new Error('Cannot lock song: No game is currently set.');
    }
    // Set status of target set to OnGoing
    return this.http.post<void>(`/admin/org/${this.organization}/game/song/lock`, null)
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  revealSong(): Observable<any> {
    if (!this.currentGameState) {
      throw new Error('Cannot reveal song: No game is currently set.');
    }
    // Set status of target set to OnGoing
    return this.http.post<void>(`/admin/org/${this.organization}/game/song/reveal`, null)
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  resetSong(): Observable<any> {
    if (!this.currentGameState) {
      throw new Error('Cannot reset song: No game is currently set.');
    }
    // Reset current song in case error occurred
    return this.http.post<void>(`/admin/org/${this.organization}/game/song/reset`, null)
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  nextSong(): Observable<any> {
    if (!this.currentGameState) {
      throw new Error('Cannot switch to next song: No game is currently set.');
    }
    // Set status of target set to OnGoing
    return this.http.post<void>(`/admin/org/${this.organization}/game/song/next`, null)
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  previousSong(): Observable<any> {
    if (!this.currentGameState) {
      throw new Error('Cannot switch to previous song: No game is currently set.');
    }
    // Set status of target set to OnGoing
    return this.http.post<void>(`/admin/org/${this.organization}/game/song/previous`, null)
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  setShowLeaderboard(show: boolean): Observable<void> {
    return this.http.post<void>(`/admin/org/${this.organization}/game/leaderboard/show/${show}`, null)
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  setEnableJokers(enable: boolean) {
    return this.http.post<void>(`/admin/org/${this.organization}/game/jokers/enable/${enable}`, null)
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  setLockPlayers(lock: boolean) {
    return this.http.post<void>(`/admin/org/${this.organization}/game/players/lock/${lock}`, null)
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

}
