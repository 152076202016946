import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminAuthGuard} from '../../core/auth/service/guards/admin-auth.guard';
import {MaterialModule} from '../../core/material/material.module';
import {CoreModule} from '../../core/core.module';
import {RouterModule} from '@angular/router';
import {BlindTestWidgetComponent} from './blindtest-widget/blindtest-widget.component';
import {DashboardComponent} from './dashboard.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {OrganizationGuard} from '../../core/services/organization/guards/organization.guard';
import {AccessButtonsComponent} from './access-buttons/access-buttons.component';
import {NoBlindtestWidgetComponent} from './no-blindtest-widget/no-blindtest-widget.component';
import {ActivateShowtimeDialogComponent} from './activate-showtime-dialog/activate-showtime-dialog.component';
import { LoadingWidgetComponent } from './loading-widget/loading-widget.component';

const routes = [
    {path: 'org/:organization/dashboard', component: DashboardComponent, canActivate: [OrganizationGuard, AdminAuthGuard]},
    {path: 'org/:organization/unauthorized', component: UnauthorizedComponent, canActivate: [OrganizationGuard]}
];


@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        CoreModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        BlindTestWidgetComponent,
        ActivateShowtimeDialogComponent,
        DashboardComponent,
        UnauthorizedComponent,
        AccessButtonsComponent,
        NoBlindtestWidgetComponent,
        LoadingWidgetComponent
    ]
})
export class DashboardModule {
}
