import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import {GameService} from '../game/game.service';
import {unsubscribe} from '../../handler/subscription-handler';
import {HttpClient} from '@angular/common/http';
import {ErrorHandler} from '../../handler/error-handler';
import {OrganizationService} from '../organization/organization.service';
import {Database, list, object, ref} from '@angular/fire/database';
import {environment} from '../../../../../environments/environment';
import {GameControls, SetControl, Song, SongPendingReview} from '@frogconnexion/blinding-common';

@Injectable({
    providedIn: 'root'
})
export class ReviewService {

    private _currentGameStateObservable: Observable<GameControls>;
    private _currentStateOfSetObservable: Observable<SetControl>;
    private _currentGameState: GameControls;
    private _currentStateOfSet: SetControl;
    private _currentPendingReviewsSubject: ReplaySubject<SongPendingReview[]>;
    private _currentSolvedPendingReviewsSubject: ReplaySubject<SongPendingReview[]>;
    private _currentPendingReviewsSubscription: Subscription;
    private _currentSolvedPendingReviewsSubscription: Subscription;
    private _currentReviewCountSubscription: Subscription;
    private _currentSongObservable: Observable<Song>;
    private _organization: string;
    private _currentSubmissionCountSubscription: Subscription;
    private _currentSubmissionCountSubject: ReplaySubject<number>;
    private _currentReviewCountSubject: ReplaySubject<number>;

    constructor(
        private database: Database,
        private organizationService: OrganizationService,
        private gameService: GameService,
        private http: HttpClient,
        private errorHandler: ErrorHandler) {

        this.organizationService.organizationTag().subscribe(o => {
            this._organization = o;
        });
        // Current Game observable
        this._currentGameStateObservable = this.gameService.currentGameControls();
        this._currentGameStateObservable.subscribe(g => {
            // Replay all game-specific observables
            this._currentGameState = g;
        });

        this._currentPendingReviewsSubject = new ReplaySubject(1);
        this._currentSolvedPendingReviewsSubject = new ReplaySubject(1);
        this._currentSubmissionCountSubject = new ReplaySubject<number>(1);
        this._currentReviewCountSubject = new ReplaySubject<number>(1);
        // Current Song observable
        this._currentSongObservable = this.gameService.currentSong();

        this._currentSongObservable.subscribe(s => {
            unsubscribe(this._currentPendingReviewsSubscription,
                this._currentSolvedPendingReviewsSubscription,
                this._currentSubmissionCountSubscription
            );
            if (!s) {
                return;
            }
            // Update subscriptions for review states
            this._currentPendingReviewsSubscription = this._pendingReviewsForCurrentSong()
                .subscribe(rs => this._currentPendingReviewsSubject.next(rs));
            this._currentSolvedPendingReviewsSubscription = this._solvedPendingReviewsForCurrentSong()
                .subscribe(rs => this._currentSolvedPendingReviewsSubject.next(rs));
            this._currentSubmissionCountSubscription = this._currentSubmissionCount()
                .subscribe(rs => this._currentSubmissionCountSubject.next(rs));
            this._currentReviewCountSubscription = this._currentReviewCount()
                .subscribe(rs => this._currentReviewCountSubject.next(rs));
        });
        // Current State of Set observable
        this._currentStateOfSetObservable = this._currentGameStateObservable
            .pipe(map(g => g ? g.setControl : null));
        this._currentStateOfSetObservable.subscribe(s => {
            this._currentStateOfSet = s;
        });
    }

    _currentSubmissionCount(): Observable<number> {
        return object(ref(this.database, `/${environment.globalNamespace}/tmp/${this._organization}/submissions/count`))
            .pipe(map(c => {
                return c.snapshot.val() || 0;
            }));
    }

    _currentReviewCount(): Observable<number> {
        return object(ref(this.database, `/${environment.globalNamespace}/tmp/${this._organization}/reviews/count`))
            .pipe(map(c => {
                return c.snapshot.val() || 0;
            }));
    }

    _pendingReviewsForCurrentSong(): Observable<SongPendingReview[]> {
        return list(ref(this.database, `/${environment.globalNamespace}/tmp/${this._organization}/pendingReviews/keys`))
            .pipe(map(changes => {
                const vals = [];
                changes.forEach(c => {
                    const r = Object.assign(new SongPendingReview, {key: c.snapshot.key, ...c.snapshot.val()});
                    vals.push(r);
                });
                return vals;
            }));
    }

    _solvedPendingReviewsForCurrentSong(): Observable<SongPendingReview[]> {
        return list(ref(this.database, `/${environment.globalNamespace}/tmp/${this._organization}/pendingReviews/keysSolved`))
            .pipe(map(changes => {
                const vals = [];
                changes.forEach(c => {
                    const r = Object.assign(new SongPendingReview, {key: c.snapshot.key, ...c.snapshot.val()});
                    vals.push(r);
                });
                return vals;
            }));
    }

    solvePendingReview(review: SongPendingReview): Observable<void> {
        return this.http.post<void>(`/admin/org/${this._organization}/game/song/review`, review)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    // Observables
    currentPendingReviewsState(): Observable<SongPendingReview[]> {
        return this._currentPendingReviewsSubject;
    }

    currentSolvedPendingReviewsState(): Observable<SongPendingReview[]> {
        return this._currentSolvedPendingReviewsSubject;
    }

    songSubmissionCount(): Observable<number> {
        return this._currentSubmissionCountSubject;
    }

    songReviewsCount(): Observable<number> {
        return this._currentReviewCountSubject;
    }

}
