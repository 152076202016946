import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ScoreService} from '../../../core/services/score/score.service';
import {Router} from '@angular/router';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {Location} from '@angular/common';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {ScoreDataSource} from '../../../core/services/score/score.datasource';
import {mergeMap, take} from 'rxjs/operators';
import {GameService} from '../../../core/services/game/game.service';
import {ErrorHandler} from '../../../core/handler/error-handler';
import {Gameplay, Player} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-malus',
    templateUrl: './malus.component.html',
    styleUrls: ['./malus.component.scss']
})
export class MalusComponent implements OnInit, OnDestroy {

    ds: ScoreDataSource;
    private _scoresSubscription: Subscription;
    bonusSize = 'medium';

    constructor(
        private _blindingService: OrganizationService,
        private _scoreService: ScoreService,
        private _gameService: GameService,
        private _router: Router,
        private _location: Location,
        private errorHandler: ErrorHandler) {
    }

    ngOnInit() {
        this.ds = new ScoreDataSource(1000, this._scoreService, this.errorHandler);
        this.onPlayerSelected = this.onPlayerSelected.bind(this);
    }

    ngOnDestroy() {
        unsubscribe(this._scoresSubscription);
    }

    onPlayerSelected(player: Player) {
        this._gameService.currentGameMetadata()
            .pipe(take(1))
            .pipe(mergeMap(meta => {
                let bonus = -1;
                if (this.bonusSize === 'medium') {
                    bonus = -2;
                }
                if (this.bonusSize === 'big') {
                    bonus = -3;
                }
                if (meta.scoreStrategy === Gameplay.FasterStrongerPerSet) {
                    bonus *= 25;
                    if (this.bonusSize === 'big') {
                        bonus = -100;
                    }
                }
                return this._scoreService.giveBonus(player.id, bonus);
            }))
            .subscribe(() => {
                this._router.navigate([`/org/${this._blindingService.getOrganizationTagSnapshot()}/game`]);
            });
    }

    back() {
        this._location.back();
    }

}
