import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {MediaPlayState} from './common/models/MediaPlayState';

import {distinctUntilChanged, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {OrganizationService} from '../../core/services/organization/organization.service';
import {ErrorHandler} from '../../core/handler/error-handler';
import {LoggerService} from '../../core/services/logger/logger.service';
import {GameService} from '../../core/services/game/game.service';
import {MediaSwitcherService} from './utils/media-switcher.service';
import {unsubscribe} from '../../core/handler/subscription-handler';
import {Database, object, ref} from '@angular/fire/database';
import {environment} from '../../../../environments/environment';
import {ShowtimeActivationDescriptor, ShowtimeSettings} from '@frogconnexion/blinding-common';
import {ShowtimeBlindingSettings} from './common/models/ShowtimeBlindingSettings';

const SHOWTIME_KEY = 'showtime_settings';

@Injectable({
    providedIn: 'root'
})
export class ShowtimeService {
    private org: string;
    private showtimeSettingsSubscription: Subscription;
    private ss: ShowtimeSettings;
    private showtimeSettingsSubject: Subject<ShowtimeSettings>;
    private remoteMediaStateSubscription: Subscription;

    constructor(
        private database: Database,
        private organizationService: OrganizationService,
        private mediaSwitcherService: MediaSwitcherService,
        private gameService: GameService,
        private errorHandler: ErrorHandler,
        private logger: LoggerService,
        private http: HttpClient) {

        this.showtimeSettingsSubject = new ReplaySubject(1);

        this.organizationService.organizationTag().subscribe(o => {
            this.org = o;
            if (o) {
                unsubscribe(this.showtimeSettingsSubscription);
                this.showtimeSettingsSubscription = object(ref(this.database, `/${environment.globalNamespace}/showtime/${o}`))
                    .subscribe(sn => {
                        this.ss = sn.snapshot.val();
                        this.showtimeSettingsSubject.next(this.ss);
                    });
            }
        });

        this.initShowtimeAutoSwitchingRules();
    }

    initShowtimeAutoSwitchingRules() {
        this.mediaStatus('feed').subscribe(s => {
            this.logger.debug('Video Feed next :' + s);
        });

        this.mediaStatus('countdown').subscribe(s => {
            this.logger.debug('Countdown next :' + s);
            if (s === MediaPlayState.END) {
                this.launchIntro().subscribe();
            }
        });

        this.mediaStatus('intro').subscribe(s => {
            this.logger.debug('Intro Video next :' + s);
            if (s === MediaPlayState.END) {
                this.launchFeed().subscribe();
            }
        });

        this.mediaStatus('connect').subscribe(s => {
            this.logger.debug('Connect Video next :' + s);
            if (s === MediaPlayState.END) {
                this.launchJoinTheGame().subscribe();
            }
        });

        this.mediaStatus('join-the-game').subscribe(s => {
            this.logger.debug('Join The Game Screen next :' + s);
        });

        this.mediaStatus('rules').subscribe(s => {
            this.logger.debug('Rules Video next :' + s);
            if (s === MediaPlayState.END) {
                this.launchFeed().subscribe();
            }
        });

        this.mediaStatus('jokers').subscribe(s => {
            this.logger.debug('Jokers Video next :' + s);
            if (s === MediaPlayState.END) {
                this.launchFeed().subscribe();
            }
        });
    }

    launchScreen(screen: string): Observable<boolean> {
        return this.http.post<boolean>(`/showtime/org/${this.org}/screen/${screen}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    launchFeed(): Observable<boolean> {
        return this.launchScreen('feed');
    }

    launchCountdown(): Observable<boolean> {
        return this.launchScreen('countdown');
    }

    launchIntro(): Observable<boolean> {
        return this.launchScreen('intro');
    }

    launchConnect(): Observable<boolean> {
        return this.launchScreen('connect');
    }

    launchRules(): Observable<boolean> {
        return this.launchScreen('rules');
    }

    launchJokers(): Observable<boolean> {
        return this.launchScreen('jokers');
    }

    launchPause(): Observable<boolean> {
        return this.launchScreen('pause');
    }

    launchEnding(): Observable<boolean> {
        return this.launchScreen('ending');
    }

    launchJoinTheGame(): Observable<boolean> {
        return this.launchScreen('join-the-game');
    }

    saveBlindingShowtimeSettings(settings: ShowtimeBlindingSettings) {
        localStorage.setItem(SHOWTIME_KEY, JSON.stringify(settings));
    }

    getBlindingShowtimeSettings(): ShowtimeBlindingSettings {
        return JSON.parse(localStorage.getItem(SHOWTIME_KEY));
    }

    currentShowtimeSettings(): Observable<ShowtimeSettings> {
        return this.showtimeSettingsSubject.pipe(distinctUntilChanged());
    }

    activateShowtime(sad: ShowtimeActivationDescriptor): Observable<boolean> {
        return this.http.post<boolean>(`/showtime/org/${this.org}`, sad)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    setTimeOfShow(date: Date): Observable<boolean> {
        return this.http.post<boolean>(`/showtime/org/${this.org}/timeofshow/${date.toISOString()}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    mediaStatus(key: string): Subject<MediaPlayState> {
        return this.mediaSwitcherService.mediaStatus(key);
    }

    mediaStatusObservable(key: string): Observable<MediaPlayState> {
        return this.mediaSwitcherService.mediaStatusObservable(key);
    }

    mediastatusNot(key: string): Subject<MediaPlayState>[] {
        return this.mediaSwitcherService.mediaStatusNot(key);
    }

    backtrackVolume(): Observable<number> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.backtrackVolume), distinctUntilChanged());
    }

    backtrackMuted(): Observable<boolean> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.backtrackMuted), distinctUntilChanged());
    }

    showtime(): Observable<string> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.showtime), distinctUntilChanged());
    }

    currentlyOnScreen(): Observable<string> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.currentlyOnScreen), distinctUntilChanged());
    }

    listenToRemoteMediaStateChanges(): void {
        unsubscribe(this.remoteMediaStateSubscription);
        this.remoteMediaStateSubscription = this.remoteMediaState().subscribe(ms => {
            if (MediaPlayState.NONE === ms) {
                return;
            }
            this.mediaStatus(this.ss?.controls?.currentlyOnScreen).next(ms);
            this.changeRemoteMediaState(MediaPlayState.NONE).subscribe();
        });
    }

    private remoteMediaState(): Observable<MediaPlayState> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.mediaState), distinctUntilChanged());
    }

    changeRemoteMediaState(state: MediaPlayState): Observable<boolean> {
        return this.http.post<boolean>(`/showtime/org/${this.org}/media/state/${state}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    setBacktrackVolume(volume: number): Observable<number> {
        return this.http.post<number>(`/showtime/org/${this.org}/backtrack/volume/${volume}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    setBacktrackMuted(muted: boolean): Observable<boolean> {
        return this.http.post<boolean>(`/showtime/org/${this.org}/backtrack/muted/${muted}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    saveWebcamModeInSettings(link: string) {
        const settings = this.getBlindingShowtimeSettings();
        settings.srcs.feedDeviceId.type = 'webcam';
        settings.srcs.feedDeviceId.link = link;
        this.saveBlindingShowtimeSettings(settings);
    }

    deactivateShowtime(): Observable<boolean> {
        return this.http.delete<boolean>(`/showtime/org/${this.org}`)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

}
