<h2 mat-dialog-title i18n="@@activateShowtimeDialog-title">Activer Showtime</h2>
<div mat-dialog-content>
    <label for="lang" class="gameplay-label" i18n="@@activateShowtimeDialog-showtimeLanguage">Langue de showtime</label>
    <br/>
    <mat-radio-group class="radio-group-row" id="lang" [(ngModel)]="outData.lang">
        <mat-radio-button class="radio-button" value="fr" i18n="@@activateShowtimeDialog-langFr">Fr</mat-radio-button>
        <mat-radio-button class="radio-button" value="en" i18n="@@activateShowtimeDialog-langEn">En</mat-radio-button>
    </mat-radio-group>
    <br/>
    <label for="type" class="gameplay-label" i18n="@@activateShowtimeDialog-showtimeType">Type de Show</label>
    <br/>
    <mat-radio-group class="radio-group" id="type" [(ngModel)]="outData.type">
        <mat-radio-button class="radio-button" value="digital" i18n="@@activateBlindtestDialog-showtimeTypeDigital">Digital</mat-radio-button>
        <mat-radio-button class="radio-button" value="irl" i18n="@@activateBlindtestDialog-showtimeTypeIRL">Présentiel</mat-radio-button>
        <mat-radio-button class="radio-button" value="hybrid" i18n="@@activateBlindtestDialog-showtimeTypeHybrid">Hybride</mat-radio-button>
    </mat-radio-group>
    <br/>
    <div class="time-picker">
        <label for="time" class="gameplay-label" i18n="@@activateShowtimeDialog-timeOfShow">Heure du show (locale)</label>
        <div id="time" class="time">
            <mat-form-field appearance="fill" class="field-time">
                <mat-select [formControl]="hour">
                    <mat-option *ngFor="let h of hours()" [value]="h">
                        {{h}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <span class="hour">h</span>
            <mat-form-field appearance="fill" class="field-time">
                <mat-select [formControl]="minute">
                    <mat-option *ngFor="let m of minutes()" [value]="m">
                        {{m}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null" i18n="@@activateShowtimeDialog-back">Retour</button>
    <button mat-button (click)="launch()" i18n="@@activateShowtimeDialog-go">Allez !</button>

</div>

