<div>
  <nav fxLayout.gt-sm='row wrap' fxLayoutAlign.gt-sm='center center' fxLayout='column'
       fxLayoutGap='10px'>

    <!-- Otherwise show admin section -->
    <div>
      <mat-card>
        <mat-card-title *ngIf="currentGameMetadata?.name">{{currentGameMetadata.name}}</mat-card-title>
        <mat-card-title *ngIf="!(currentGameMetadata?.name)" i18n="@@videoControl-noGameOngoing">Pas de partie en cours</mat-card-title>
        <mat-card-subtitle>
          <!-- Game Status -->
          <mat-chip-list>
            <mat-chip>PIN:{{currentGameMetadata?.pin.toUpperCase()}}</mat-chip>
            <mat-chip *ngIf='currentGameState?.blindtestControl.status === 0' i18n="@@videoControl-ready">Prêt</mat-chip>
            <mat-chip *ngIf="!playerCount">0 Joueurs</mat-chip>
            <mat-chip *ngIf="playerCount && playerCount != 1" i18n="@@videoControl-playerCount">{{playerCount}} Joueurs</mat-chip>
            <mat-chip *ngIf="playerCount === 1">1 Joueur</mat-chip>
            <mat-chip color="primary" *ngIf='currentGameState?.blindtestControl.status === 1' i18n="@@videoControl-ongoing">En cours</mat-chip>
            <mat-chip color="accent" *ngIf='currentGameState?.blindtestControl.status === 2' i18n="@@videoControl-paused">En pause</mat-chip>
            <mat-chip color="accent" *ngIf='currentGameState?.blindtestControl.isRunning()' i18n="@@videoControl-setNumber">
              Set N°
              {{currentGameState?.blindtestControl.current + 1}} / {{currentGameState?.blindtestControl.setCount}}
            </mat-chip>
            <mat-chip *ngIf='currentGameState?.blindtestControl.status === 3' i18n="@@videoControl-finished">Terminé</mat-chip>
          </mat-chip-list>
        </mat-card-subtitle>
        <mat-card-content>
          <!-- Game Controls when game is OnGoing -->
          <button mat-raised-button color="primary" *ngIf='currentGameState?.blindtestControl.isRunning()'
                  routerLink="../game/bonus">Bonus
          </button>
          <button mat-raised-button color="primary" *ngIf='currentGameState?.blindtestControl.isRunning()'
                  routerLink="../game/malus">Malus
          </button>
        </mat-card-content>
      </mat-card>
      <app-showtime-controls *ngIf="ss"
                             [currentShowtimeSettings]="ss"
                             [currentGameMetadata]="currentGameMetadata"
                             [currentGameControls]="currentGameState"></app-showtime-controls>
    </div>
  </nav>
</div>

