import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ShowtimeService} from '../../showtime/showtime.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivateShowtimeDialogComponent} from '../activate-showtime-dialog/activate-showtime-dialog.component';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {ActivatedRoute, Router} from '@angular/router';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {GameMetadata, ShowtimeActivationDescriptor, ShowtimeSessionType, ShowtimeSettings} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-access-buttons',
    templateUrl: './access-buttons.component.html',
    styleUrls: ['./access-buttons.component.scss']
})
export class AccessButtonsComponent implements OnInit, OnDestroy {

    @Input()
    ss: ShowtimeSettings;
    @Input()
    gm: GameMetadata;
    @Input()
    loading = false;
    private activateDialogSubscription: Subscription;

    constructor(private showtimeService: ShowtimeService,
                private organizationService: OrganizationService,
                private activatedRoute: ActivatedRoute,
                private router: Router, private dialog: MatDialog) {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        unsubscribe(this.activateDialogSubscription);
    }


    deactivate() {
        this.showtimeService.deactivateShowtime().subscribe();
    }

    activate() {
        const dialogRef = this.dialog.open(ActivateShowtimeDialogComponent, {
            data: {
                gameLang: this.gm?.lang
            },
            width: '300px'
        });
        this.activateDialogSubscription = dialogRef.afterClosed().subscribe((data) => {
            this.activateDialogSubscription.unsubscribe();
            this.activateDialogSubscription = undefined;
            if (data) {
                this.showtimeService.activateShowtime(new ShowtimeActivationDescriptor('black', data.lang, data.type, data.timeOfShow))
                    .subscribe(() => {
                        window.location.reload();
                    });
            }
        });
    }

    openShowtimeDigitalTab() {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([`/org/${this.organizationService.getOrganizationTagSnapshot()}/showtime/digital`])
        );
        window.open(url, '_blank');
    }

    openShowtimeIrlTab() {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([`/org/${this.organizationService.getOrganizationTagSnapshot()}/showtime/irl`])
        );
        window.open(url, '_blank');
    }

    isUnsetOrDigital() {
        return !this.ss || this.ss.metadata?.type === ShowtimeSessionType.DIGITAL;
    }

    isUnsetOrIrl() {
        return !this.ss || this.ss.metadata?.type === ShowtimeSessionType.IRL;
    }

    isUnsetIrlOrHybrid() {
        return !this.ss || this.ss.metadata?.type === ShowtimeSessionType.IRL || this.ss.metadata?.type === ShowtimeSessionType.HYBRID;
    }

    isUnsetDigitalOrHybrid() {
        return !this.ss || this.ss.metadata?.type === ShowtimeSessionType.DIGITAL || this.ss.metadata?.type === ShowtimeSessionType.HYBRID;
    }
}
