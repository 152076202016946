<mat-card>
    <mat-card-header>
        <img mat-card-avatar src="/assets/imgs/logo.png" alt='BlindTest Avatar'/>
        <mat-card-title i18n="@@dashboardNoGame-title">Aucune partie n'est activée</mat-card-title>
        <mat-card-subtitle *ngIf="ss">
            <ng-container i18n="@@dashboardNoGame-sessionActive">Une session Showtime est active.</ng-container>
            <br/>
            <ng-container i18n="@@dashboardNoGame-lang">Langue showtime :</ng-container> &nbsp;<b>{{ss?.metadata?.lang || 'N/A'}}</b>
            <br/>
            <span *ngIf="ss?.controls?.showtime"><ng-container i18n="@@dashboardNoGame-timeOfShow">Heure programmée :</ng-container> <b>{{(ss?.controls?.showtime | date:'dd/MM hh:mm') || 'N/A'}}</b></span>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="!ss" i18n="@@dashboardNoGame-sessionInactive">
            Showtime est inactif
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content i18n="@@dashboardNoGame-tellTeamToEnableTheGame">
        Demandez bien à l'équipe d'activer la partie afin de pouvoir accéder aux fonctionnalités Showtime.
    </mat-card-content>
    <mat-card-actions>
        <app-access-buttons [ss]="ss"></app-access-buttons>
    </mat-card-actions>
</mat-card>
