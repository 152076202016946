import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ScoreService} from './score.service';
import {catchError} from 'rxjs/operators';
import {ErrorHandler} from '../../handler/error-handler';
import {LeaderboardEntry} from '@frogconnexion/blinding-common';

export class ScoreDataSource extends DataSource<LeaderboardEntry | undefined> {
  private _pageSize = 20;
  private _cachedData = Array.from<LeaderboardEntry>({length: this._length});
  private _fetchedPages = new Set<number>();
  private _dataStream = new BehaviorSubject<(LeaderboardEntry | undefined)[]>(this._cachedData);
  private _subscription = new Subscription();


  constructor(private _length: number = 1000, private _scoreService: ScoreService, private errorHandler: ErrorHandler) {
    super();
  }

    connect(collectionViewer: CollectionViewer): Observable<(LeaderboardEntry | undefined)[]> {
    this._subscription.add(collectionViewer.viewChange.subscribe(range => {
      const startPage = this._getPageForIndex(range.start);
      const endPage = this._getPageForIndex(range.end - 1);
      for (let i = startPage; i <= endPage; i++) {
        this._fetchPage(i);
      }
    }));
    this._initDummyData(5);
    return this._dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  private _initDummyData(len: number) {
    this._dataStream.next(Array.from({length: len}).map((_, i) => null));
  }

  private _getPageForIndex(index: number): number {
    return Math.floor((index + 1) / this._pageSize);
  }

  private _fetchPage(page: number) {
    if (this._fetchedPages.has(page)) {
      return;
    }
    console.log('Fetching page ' + page);
    this._fetchedPages.add(page);

    this._scoreService.fetchScores(page * this._pageSize)
        .pipe(catchError(err => this.errorHandler.handleErrorAndLog(err)))
        .subscribe((items) => {
      this._cachedData.splice(page * this._pageSize, this._pageSize,
        ...items);
      if (this._length > 0 && this._cachedData.length > this._length) {
        this._cachedData.splice(this._length, this._cachedData.length - this._length);
      }
      this._dataStream.next(this._cachedData);
    });
  }
}
