<div class="main">
    <div class="video-container">
        <div class="label">{{label}}</div>
        <div class="row">
            <div class="countdown" *ngIf="remainingSeconds >= 60">{{remainingMinutes | number: '2.0-0'}}:</div>
            <div class="countdown" *ngIf="remainingSeconds >= 0">{{remainingSeconds % 60}}</div>
        </div>
        <mat-progress-bar
                class="example-margin"
                color="primary"
                mode="determinate"
                [value]="progress">
        </mat-progress-bar>
    </div>
</div>

