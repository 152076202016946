import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '../../../core/auth/service/auth.service';

import {BlindtestService} from '../../../core/services/blindtest/blindtest.service';

import {MatDialog} from '@angular/material/dialog';
import {GameService} from '../../../core/services/game/game.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {ScoreService} from '../../../core/services/score/score.service';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {ShowtimeService} from '../../showtime/showtime.service';
import {Organization} from '@frogconnexion/core-common';
import {Blindtest, GameControls, GameMetadata, Gameplay, Scoresheet, ShowtimeSettings} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-blindtest-widget',
    templateUrl: './blindtest-widget.component.html',
    styleUrls: ['./blindtest-widget.component.scss']
})
export class BlindTestWidgetComponent implements OnChanges, OnDestroy {
    @Input() organization: Organization;
    @Input() blindtest: Blindtest;
    @Input() currentGameState: GameControls;
    @Input() currentGameMetadata: GameMetadata;
    @Input() ss: ShowtimeSettings;
    isFinished: boolean;
    error: any;

    private _activateDialogSubscription: Subscription;
    private _disableDialogSubscription: Subscription;
    private _createGameSubscription: Subscription;
    private _unsetGameSubscription: Subscription;

    constructor(
        private _blindingService: OrganizationService,
        private showtimeService: ShowtimeService,
        private authService: AuthService, private _blindTestService: BlindtestService,
        private _gameService: GameService, private _scoreService: ScoreService,
        private _router: Router, private _dialog: MatDialog) {
    }



    ngOnChanges() {
        this.isFinished = this.currentGameState && (this.currentGameState.isFinished());
    }

    ngOnDestroy() {
        unsubscribe(
            this._activateDialogSubscription,
            this._disableDialogSubscription,
            this._createGameSubscription,
            this._unsetGameSubscription);
    }

    getGameplay() {
        return Gameplay.name(this.currentGameMetadata.scoreStrategy);
    }

    getSetCount() {
        if (!this.blindtest || !this.blindtest.sets) {
            return 0;
        }
        return this.blindtest.sets.length;
    }

    getItemsCount() {
        if (!this.blindtest || !this.blindtest.sets) {
            return 0;
        }
        let count = 0;
        this.blindtest.sets.forEach(s => {
            if (!s.sections) {
                return;
            }
            s.sections.forEach(sc => count += sc.endIndex - sc.startIndex);
        });
        return count;
    }

    exportScoresheet() {
        this._scoreService.export().subscribe((scores) => {
            // tslint:disable-next-line:forin
            for (const i in scores) {
                this.downloadFile(scores[i]);
            }
        });
    }

    downloadFile(data: Scoresheet) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data.payload));
        element.setAttribute('download', `Leblindtest.fr - ${this.blindtest.name} - ${data.prefix} - set ${data.set + 1}.csv`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }


    deactivate() {
        this.showtimeService.deactivateShowtime().subscribe();
    }
}
