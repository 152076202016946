import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {BlindtestService} from '../../services/blindtest/blindtest.service';
import {Build} from '@frogconnexion/core-common';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  env = environment;
  build: Build;
  version: string;

  constructor(private blindtestService: BlindtestService) {
    this.version = environment.version;
  }

  ngOnInit() {
    this.blindtestService.build().subscribe(build => {
      this.build = build;
    });
  }

}
