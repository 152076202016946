import {Component, OnDestroy, OnInit} from '@angular/core';

import {AuthService} from '../../auth/service/auth.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {GameService} from '../../services/game/game.service';
import {unsubscribe} from '../../handler/subscription-handler';
import {NavigationService} from '../../services/navigation/navigation.service';
import {OrganizationService} from '../../services/organization/organization.service';
import {AuthUser, Claims, RoleClaim} from '@frogconnexion/core-common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  constructor(private _authService: AuthService,
              private _router: Router,
              private _gameService: GameService,
              private _blindingService: OrganizationService,
              private _navigationService: NavigationService) {
  }

  authenticated: boolean;
  authorizedUser: boolean;
  hasCurrentGame: boolean;
  organization: string;
  user: AuthUser = AuthUser.anonymousUnauthenticated();
  header: string;
  private _currentUserSubscription: Subscription;
  private _gameSubscription: Subscription;
  private _navHeaderSubscription: Subscription;
  private _organizationSubscription: Subscription;

  static toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  ngOnInit() {
    this._organizationSubscription = this._blindingService.organizationTag().subscribe(o => {
      this.organization = o;
    });
    this._currentUserSubscription = this._authService.currentUserObservable().subscribe(user => {
      if (!user) {
        return;
      }
      this.authenticated = !user.anonymous;
      if (user.anonymous) {
        this.authorizedUser = false;
        return;
      }
      this.user = user;
      this.authorizedUser = user?.hasAnyRole([
        RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_ADMIN),
        RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_OPERATOR),
        RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_MANAGER, this._blindingService.getOrganizationTagSnapshot())
      ]);
    });
    this._gameSubscription = this._gameService.currentGameControls().subscribe(gs => {
      this.hasCurrentGame = !!gs;
    });
    this._navHeaderSubscription = this._navigationService.navHeader().subscribe(h => {
      this.header = NavbarComponent.toTitleCase(h);
    });
  }

  ngOnDestroy() {
    unsubscribe(this._currentUserSubscription, this._gameSubscription, this._navHeaderSubscription, this._organizationSubscription);
  }


  logout() {
    this._authService.signOut()
      .then(() => this._router.navigate(['/']));
  }

}
