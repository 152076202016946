<mat-toolbar>
  <div class="container">
    <!-- Avatar -->
    <img class="logo-bubble" src="/assets/imgs/logo.png" />
    <!-- Title -->
    <div class="title">
      <span *ngIf="header">{{header}}</span>
      <span *ngIf="!(header)">GoBlindtest Screen</span>
    </div>
    <span class="fill-remaining-space"></span>

    <div fxLayout="row" fxShow="false" fxShow.gt-sm>
      <button *ngIf="!authenticated" routerLink="/" mat-button>Portail</button>
      <button *ngIf="authorizedUser && organization" [routerLink]="'/org/' + organization + '/dashboard'" mat-button>Dashboard</button>
      <button *ngIf="authenticated" (click)="logout()" mat-button>Logout</button>
    </div>
    <button mat-icon-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</mat-toolbar>

<mat-menu class="sub-menu" fxLayout="column" x-position="before" #menu="matMenu" [overlapTrigger]="false">
  <button [routerLink]="'/org/' + organization + '/dashboard'" mat-menu-item>Dashboard</button>
  <button *ngIf="authenticated" (click)="logout()" mat-menu-item>Logout</button>
</mat-menu>
