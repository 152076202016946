import {Injectable} from '@angular/core';

import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map, take} from 'rxjs/operators';
import {unsubscribe} from '../../handler/subscription-handler';
import {ErrorHandler} from '../../handler/error-handler';
import {LoggerService} from '../logger/logger.service';
import {Database, object, objectVal, ref} from '@angular/fire/database';
import {environment} from '../../../../../environments/environment';
import {Organization, OrganizationHttp} from '@frogconnexion/core-common';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private organizationSubject: Subject<Organization>;
  private organizationTagSubject: Subject<string>;
  private organizationSubscription: Subscription;
  private organizationTagSnapshot: string;
  private _organizationSnapshot: Organization;

  constructor(private database: Database,
              private http: HttpClient,
              private errorHandler: ErrorHandler) {

    this.organizationSubject = new ReplaySubject<Organization>(1);
    this.organizationTagSubject = new ReplaySubject<string>(1);
    this.organizationTagSubject.subscribe((organization: string) => {
      this.organizationTagSnapshot = organization;
      unsubscribe(this.organizationSubscription);
      this.organizationSubscription = object(ref(this.database, `/organizations/${organization}`)).subscribe(sn => {
        const o = sn.snapshot.val();
        const org = Organization.fromFirebase(o);
        this._organizationSnapshot = org;
        this.organizationSubject.next(org);
      });
    });
  }

  updateOrganization(organization: string) {
    this.organizationTagSubject.next(organization);
  }

  // Observables
  organization(): Observable<Organization> {
    return this.organizationSubject;
  }

  organizationTag(): Observable<string> {
    return this.organizationTagSubject;
  }

  getOrganizationTagSnapshot(): string {
    return this.organizationTagSnapshot;
  }

  getOrganizationSnapshot(): Organization {
    return this._organizationSnapshot;
  }

  isValid(organization: string): Observable<boolean> {
    return this.http.get<OrganizationHttp>(`/public/org/${organization}/appstate`)
      .pipe(this.errorHandler.retryThreeTimesOrError())
      .pipe(map((blinding: OrganizationHttp) => {
        return !!blinding.organization;
      }));
  }

  isPinAvailable(pin: string): Observable<boolean> {
    return object(ref(this.database, `/${environment.globalNamespace}/pins/${pin}`))
        .pipe(take(1),
            map(s => !s.snapshot.exists())
        );
  }
}
