import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-widget',
  templateUrl: './loading-widget.component.html',
  styleUrls: ['./loading-widget.component.scss']
})
export class LoadingWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
