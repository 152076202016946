import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import {AuthService} from '../service/auth.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {

  validationMessages = {
    'user': [
      { type: 'required', message: 'Nom requis.'},
      { type: 'minlength', message: 'Merci d\'entrer un nom valide (plus de 3 lettres)' }
    ],
    'email': [
      { type: 'required', message: 'Email requis' },
      { type: 'email', message: 'Merci d\'entrer un email valide' }
    ],
    'confirmPassword': [
      { type: 'required', message: 'Confirmation du mot de passe obligatoire' }
    ],
    'password': [
      { type: 'required', message: 'Mot de passe requis' },
      { type: 'minlength', message: 'Le mot de passe doit au moins faire 6 lettres' }
    ],
    'passwords': [
      { type: 'areEqual', message: 'Les mots de passe ne concordent pas' }
    ],
    'avatar': [
      { type: 'required', message: 'Merci de choisir un avatar' }
    ],
    'terms': [
      { type: 'pattern', message: 'You must accept terms and conditions' }
    ]
  };


  passwordFormGroup = new UntypedFormGroup({
    'password': new UntypedFormControl('',
      Validators.compose([Validators.minLength(6), Validators.required])),
    'confirmPassword': new UntypedFormControl('', Validators.required)
  }, SignupComponent.areEqual);

  signupForm = new UntypedFormGroup({
    'user': new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    'email': new UntypedFormControl('', [Validators.required, Validators.email]),
    'passwordGroup': this.passwordFormGroup,
    'avatar': new UntypedFormControl('', [Validators.required])
  });

  matcher = new MyErrorStateMatcher();

  hide: boolean;

  error: any;

  constructor(private authService: AuthService,
              private _router: Router) {}

  static areEqual(formGroup: UntypedFormGroup) {
    let value;
    let valid = true;
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: UntypedFormControl = <UntypedFormControl>formGroup.controls[key];

        if (value === undefined) {
          value = control.value;
        } else if (value !== control.value) {
          valid = false;
          break;
        }

      }
    }
    if (valid) {
      return null;
    }

    return {
      areEqual: true
    };
  }

  get user() { return this.signupForm.get('user'); }

  get email() { return this.signupForm.get('email'); }

  get avatar() { return this.signupForm.get('avatar'); }

  get password() { return this.signupForm.get('passwordGroup').get('password'); }

  get confirmPassword() { return this.signupForm.get('passwordGroup').get('confirmPassword'); }

  onSubmit() {
    this.authService.registerUser(this.email.value, this.password.value)
      .then(() => {
        this._router.navigate(['/register']);
      })
      .catch((error) => {
        this.error = 'L\'adresse email \'' + this.email.value + '\' est déjà utilisée.';
      });
  }
}
