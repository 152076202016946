<div *ngIf="!isLogged; else alreadyLoggedIn" class="container">
  <mat-card class="login">
    <mat-card-content>
      <div fxLayout="column"
           fxLayoutAlign="space-between center"
           fxLayoutGap="20px">
        <mat-icon>lock</mat-icon>
        <p>Tu es sur l'interface d'administration de <b>Blinding</b> pour <b>piloter</b> ton blind-test.</p>
        <button mat-raised-button (click)="loginFb()" id="fb">Connecter via Facebook</button>
        <button mat-raised-button (click)="loginGoogle()" id="google">Connecter via Google</button>
        <button mat-raised-button routerLink="../login-email" id="email">Avec ton Email</button>
      </div>
    </mat-card-content>
    <mat-card-footer>
      Tu souhaites avoir un compte ? <br/>Contacte-nous :<br/>contact@leblindtest.fr
    </mat-card-footer>
  </mat-card>
</div>

<ng-template #alreadyLoggedIn>
  <div class="container">
    <mat-card class="login">
      <mat-card-content>
        <div fxLayout="column"
             fxLayoutAlign="space-between center"
             fxLayoutGap="20px">
          <h2 style="text-align:center">Tu es déjà connecté(e) !</h2>
          <button mat-raised-button routerLink="/dashboard">Je veux jouer!</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
