import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {path: 'org/:organization/showtime/digital', loadChildren: () => import('./digital/showtime-digital.module').then(m => m.ShowtimeDigitalModule)},
    {path: 'org/:organization/showtime/irl', loadChildren: () => import('./irl/showtime-irl.module').then(m => m.ShowtimeIrlModule)},
];

@NgModule({
    imports: [RouterModule.forChild(routes)]
})
export class ShowtimeRoutingModule { }
