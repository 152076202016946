import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {OrganizationService} from '../../organization/organization.service';
import {LoggerService} from '../../logger/logger.service';
import {Claims, Organization, PropertyClaim} from '@frogconnexion/core-common';

@Injectable({
  providedIn: 'root'
})
export class GameGuard implements CanActivate {

  constructor(private organizationService: OrganizationService,
              private router: Router,
              private logger: LoggerService) {
  }

  canActivate(): Observable<boolean> {
    return this.organizationService.organization()
      .pipe(map((o: Organization) => {
          const claim = PropertyClaim.parse(Claims.Organization.BLINDING_GLOBAL_PROP_HAS_CURRENT_GAME);
          return o?.properties?.find(p => p.claimKey === claim.claimKey)?.value === true;
      }))
      .pipe(map(hasCurrentGame => {
        if (!hasCurrentGame) {
          this.logger.debug('Game Guard Failed. Redirecting.');
          this.router.navigate(['/dashboard']);
          return false;
        }
        this.logger.debug('Game Guard Succeeded.');
        return true;
      }));
  }
}
