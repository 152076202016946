import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-live-preview-widget',
  templateUrl: './live-preview-widget.component.html',
  styleUrls: ['./live-preview-widget.component.scss']
})
export class LivePreviewWidgetComponent implements OnInit, OnChanges {

  @Input()
  label: string;
  @Input()
  totalDurationInSeconds: number;
  remainingSeconds: number;
  private timeout: any;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.remainingSeconds = this.totalDurationInSeconds;
    if (this.timeout) {
      clearInterval(this.timeout);
    }
    this.timeout = setInterval(() => {
      this.remainingSeconds = Math.max(0, this.remainingSeconds - 1);
      if (this.remainingSeconds === 0) {
        clearInterval(this.timeout);
      }
    }, 1000);
  }

  get progress() {
    if (this.totalDurationInSeconds === 0) {
      return 0;
    }
    return 100 * (this.totalDurationInSeconds - this.remainingSeconds) / (this.totalDurationInSeconds);
  }

  get remainingMinutes() {
    return Math.floor(this.remainingSeconds / 60);
  }

}
