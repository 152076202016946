import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LoggerService} from '../../../core/services/logger/logger.service';
import {GameService} from '../../../core/services/game/game.service';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {UntypedFormControl} from '@angular/forms';
import {Organization} from '@frogconnexion/core-common';
import {ShowtimeSessionType} from '@frogconnexion/blinding-common';

export interface DialogData {
    organization: Organization;
    gameLang: string;
}

export interface OutDialogData {
    lang: string;
    type: ShowtimeSessionType;
    date: string;
}

@Component({
    selector: 'app-activate-showtime-dialog',
    templateUrl: 'activate-showtime-dialog.component.html',
    styleUrls: ['./activate-showtime-dialog.component.scss'],
})
export class ActivateShowtimeDialogComponent {

    outData: OutDialogData = {
        'type': ShowtimeSessionType.DIGITAL,
        'date': null,
        'lang': 'fr',
    };
    private gameService: GameService;
    private organizationService: OrganizationService;
    brandingLogoError: string;
    hour = new UntypedFormControl(18);
    minute = new UntypedFormControl(0);

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
                public dialogRef: MatDialogRef<ActivateShowtimeDialogComponent>,
                private logger: LoggerService) {
        if (data.gameLang) {
            this.outData.lang = data.gameLang;
        }
    }

    launch() {
        const date = new Date();
        date.setHours(this.hour.value, this.minute.value, 0, 0);
        this.outData.date = date.toISOString();
        console.log(this.outData);
        this.dialogRef.close(this.outData);
    }

    hours() {
        return [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7, 8];
    }

    minutes() {
        return [0, 15, 30, 45];
    }

}
