<mat-card>
    <mat-card-subtitle>
        <mat-chip-list>
            <mat-chip color="primary" *ngIf="currentShowtimeSettings?.controls?.currentlyOnScreen">Screen : {{currentShowtimeSettings?.controls?.currentlyOnScreen}}</mat-chip>
            <mat-chip color="primary" i18n="@@showtimeControls-countdownTip">Countdown : {{countdown[0] | number : '2.0'}}:{{countdown[1] | number : '2.0'}}</mat-chip>
        </mat-chip-list>
    </mat-card-subtitle>
    <mat-card-content>
        <h3 i18n="@@showtimeControls-changeCountdown">Changer le Countdown</h3>
        <div class="btn-group countdown">
            <button mat-raised-button color="accent" (click)="changeShowtime(-60)">-1min</button>
            <button mat-raised-button color="accent" (click)="changeShowtime(-10)">-10s</button>
            <button mat-raised-button color="accent" (click)="changeShowtime(0)">NOW</button>
            <button mat-raised-button color="accent" (click)="changeShowtime(10)">+10s</button>
            <button mat-raised-button color="accent" (click)="changeShowtime(60)">+1min</button>
        </div>
        <h3>Vidéo</h3>
        <div class="btn-group feed">
            <button mat-raised-button [color]="currentlyOnScreen === 'black' ? 'primary' : 'accent'" (click)="setShowtimeScreen('black')" i18n="@@showtimeControls-black">Noir</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'feed' ? 'primary' : 'accent'" (click)="setShowtimeScreen('feed')" i18n="@@showtimeControls-feed">Caméra</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'countdown' ? 'primary' : 'accent'" (click)="setShowtimeScreen('countdown')" i18n="@@showtimeControls-countdown">Countdown</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'pause' ? 'primary' : 'accent'" (click)="setShowtimeScreen('pause')" i18n="@@showtimeControls-pause">Pause</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'ending' ? 'primary' : 'accent'" (click)="setShowtimeScreen('ending')" i18n="@@showtimeControls-ending">Ending</button>
        </div>
        <div class="btn-group feed">
            <button mat-raised-button [color]="currentlyOnScreen === 'intro' ? 'primary' : 'accent'" (click)="setShowtimeScreen('intro')">Intro</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'connect' ? 'primary' : 'accent'" (click)="setShowtimeScreen('connect')">Connect</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'join-the-game' ? 'primary' : 'accent'" (click)="setShowtimeScreen('join-the-game')">Join-Game</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'rules' ? 'primary' : 'accent'" (click)="setShowtimeScreen('rules')">Rules</button>
            <button mat-raised-button [color]="currentlyOnScreen === 'jokers' ? 'primary' : 'accent'" (click)="setShowtimeScreen('jokers')">Jokers</button>
        </div>
        <br/>
        <app-live-preview-widget [totalDurationInSeconds]="totalDurationInSeconds" [label]="currentlyOnScreen"></app-live-preview-widget>
        <h3>Audio</h3>
        <!--<b>Main Out :</b><span *ngIf="isSoundActive" class="green">Activé</span><span *ngIf="!isSoundActive" class="red">Désactivé</span>-->
        <b i18n="@@showtimeControls-backtrack">Backtrack :</b><span *ngIf="!backtrackMuted" class="green" i18n="@@showtimeControls-enabled">Activé</span><span *ngIf="backtrackMuted" class="red" i18n="@@showtimeControls-disabled">Désactivé</span>
        <br/><br/>
        <div>
            <button mat-raised-button color="primary" *ngIf="!backtrackMuted" (click)="setBacktrackMuted(true)">Mute</button>
            <button mat-raised-button color="primary" *ngIf="backtrackMuted" (click)="setBacktrackMuted(false)">Unmute</button>
            <mat-slider [min]="0" [max]="85" (valueChange)="setBacktrackVolume($event)"></mat-slider>
        </div>
    </mat-card-content>
</mat-card>
