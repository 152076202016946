<mat-card>
  <mat-card-title>Donner un Bonus</mat-card-title>
  <mat-card-subtitle>Sélectionner le joueur</mat-card-subtitle>
  <mat-card-content>
    <mat-radio-group class="radio-group" id="bonusSize" [(ngModel)]="bonusSize">
      <mat-radio-button class="radio-button" value="small">Mini</mat-radio-button>
      <mat-radio-button class="radio-button" value="medium">Normal</mat-radio-button>
      <mat-radio-button class="radio-button" value="big">Big</mat-radio-button>
    </mat-radio-group>
    <app-player-selector [onPlayerSelected]="onPlayerSelected"></app-player-selector>
    <div class="button-container">
      <button mat-raised-button color="primary" (click)="back()">
        Retour
      </button>
    </div>
  </mat-card-content>
</mat-card>
