import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private navigationSubject: ReplaySubject<string>;

  constructor() {
    this.navigationSubject = new ReplaySubject<string>(1);
  }

  updateNavHeader(name: string) {
    this.navigationSubject.next(name);
  }

  navHeader(): Observable<string> {
    return this.navigationSubject;
  }


}
