<div class="btn-group" *ngIf="!loading">
    <button mat-raised-button color="warn" (click)="deactivate()" *ngIf="ss">
        <mat-icon>clear</mat-icon>
        <ng-container i18n="@@dashboardAccessButtons-terminate">Terminer la Session</ng-container>
    </button>
    <button mat-raised-button color="accent" (click)="activate()" *ngIf="!ss">
        <mat-icon>toggle_on</mat-icon>
        <ng-container i18n="@@dashboardAccessButtons-start">Démarrer une Session</ng-container>
    </button>
    <button mat-raised-button color="primary" routerLink='../video-control' [disabled]="!ss">
        <mat-icon>sports_esports</mat-icon>
        <ng-container i18n="@@dashboardAccessButtons-videoControl">Controle Régie</ng-container>
    </button>
    <button class="hide-mobile" mat-raised-button color="primary" (click)="openShowtimeIrlTab()" [disabled]="isUnsetOrDigital()">
        <mat-icon>tv</mat-icon>
        <ng-container i18n="@@dashboardAccessButtons-showtimeIrl">Diff. Présentiel</ng-container>
    </button>
    <button class="hide-mobile" mat-raised-button color="primary" (click)="openShowtimeDigitalTab()" [disabled]="isUnsetOrIrl()">
        <mat-icon>movie_filter</mat-icon>
        <ng-container i18n="@@dashboardAccessButtons-showtimeDigital">Diff. Digital</ng-container>
    </button>
    <button mat-raised-button routerLink='/'>
        <mat-icon>logout</mat-icon>
        <ng-container i18n="@@dashboardAccessButtons-changeTeam">Changer d'équipe</ng-container>
    </button>
</div>
<div class="btn-group" *ngIf="loading">
    <button mat-raised-button disabled>
        <mat-icon>toggle_on</mat-icon>
        <ng-container i18n="@@dashboardAccessButtons-start">Démarrer une Session</ng-container>
    </button>
    <button mat-raised-button disabled>
        <mat-icon>sports_esports</mat-icon>
        <ng-container i18n="@@dashboardAccessButtons-videoControl">Controle Régie</ng-container>
    </button>
    <button class="hide-mobile" mat-raised-button disabled>
        <mat-icon>tv</mat-icon>
        <ng-container i18n="@@dashboardAccessButtons-showtimeIrl">Diff. Présentiel</ng-container>
    </button>
    <button class="hide-mobile" mat-raised-button disabled>
        <mat-icon>movie_filter</mat-icon>
        <ng-container i18n="@@dashboardAccessButtons-showtimeDigital">Diff. Digital</ng-container>
    </button>
    <button mat-raised-button routerLink='/'>
        <mat-icon>logout</mat-icon>
        <ng-container i18n="@@dashboardAccessButtons-changeTeam">Changer d'équipe</ng-container>
    </button>
</div>
