import {Injectable} from '@angular/core';

import {Database, objectVal, ref} from '@angular/fire/database';

import {Observable, ReplaySubject, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from '../logger/logger.service';
import {OrganizationService} from '../organization/organization.service';
import {ErrorHandler} from '../../handler/error-handler';
import {environment} from '../../../../../environments/environment';
import {Build, Claims, PropertyClaim} from '@frogconnexion/core-common';
import {Blindtest} from '@frogconnexion/blinding-common';

@Injectable({
  providedIn: 'root'
})
export class BlindtestService {

  private blindtestSubject: Subject<Blindtest>;
  private currentBlindTest: Blindtest;
  private organization: string;

  constructor(private database: Database,
              private organizationService: OrganizationService,
              private http: HttpClient,
              private logger: LoggerService,
              private errorHandler: ErrorHandler) {

    this.blindtestSubject = new ReplaySubject(null);
    this.organizationService.organization().subscribe(o => {
      this.organization = o?.organization;
      // If blinding has changed, compute and emit next blind test value
      const claim = PropertyClaim.parse(Claims.Organization.BLINDING_GLOBAL_PROP_HAS_CURRENT_GAME);
      const shouldLoadBlindtest: boolean = o?.properties.find(p => p.claimKey === claim.claimKey)?.value === true;
      if (shouldLoadBlindtest) {
        this.loadCurrent().subscribe(bt => this.updateCurrent(bt));
      } else {
        this.updateCurrent(null);
      }
    });
  }

  private updateCurrent(value: Blindtest) {
    this.currentBlindTest = value;
    this.blindtestSubject.next(value);
  }

  private loadCurrent(): Observable<Blindtest> {
    return objectVal(ref(this.database, `${environment.globalNamespace}/games/${this.organization}/game/blindtest`));
  }

  // Async methods

  // Observables

  currentBlindtest(): Observable<Blindtest> {
    return this.blindtestSubject;
  }

  get(id: string): Observable<Blindtest> {
    const organization = this.organizationService.getOrganizationTagSnapshot();
    return this.http.get<Blindtest>(`/admin/org/${organization}/blindtests/${id}`)
      .pipe(map(bt => Blindtest.fromDto(bt)))
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  allBlindTests(): Observable<Blindtest[]> {
    const organization = this.organizationService.getOrganizationTagSnapshot();
    return this.http.get<Blindtest[]>(`/admin/org/${organization}/blindtests`)
      .pipe(map(bts => bts.map(bt => Blindtest.fromDto(bt))))
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  build(): Observable<Build> {
    return this.http.get<Build>('/public/build')
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  save(blindtest: Blindtest): Observable<Blindtest> {
    const organization = this.organizationService.getOrganizationTagSnapshot();
    return this.http.post<Blindtest>(`/admin/org/${organization}/blindtests`, blindtest)
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

  delete(id: string): Observable<any> {
    const organization = this.organizationService.getOrganizationTagSnapshot();
    return this.http.delete<any>(`/admin/org/${organization}/blindtests/${id}`)
      .pipe(this.errorHandler.retryThreeTimesOrError());
  }

}
