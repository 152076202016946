import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../core/auth/service/auth.service';
import {BlindtestService} from '../../core/services/blindtest/blindtest.service';
import {GameService} from '../../core/services/game/game.service';
import {unsubscribe} from '../../core/handler/subscription-handler';
import {NavigationService} from '../../core/services/navigation/navigation.service';
import {environment} from '../../../../environments/environment';
import {OrganizationService} from '../../core/services/organization/organization.service';
import {ShowtimeService} from '../showtime/showtime.service';
import {Build, Claims, Organization, RoleClaim} from '@frogconnexion/core-common';
import {Blindtest, GameControls, GameMetadata, ShowtimeSettings} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    blindtests: Blindtest[];
    currentGameState: GameControls;
    currentGameMetadata: GameMetadata;
    currentBlindtest: Blindtest;
    build: Build;
    env = environment;

    hasActiveGame: boolean = undefined;
    hasShowtime: boolean = undefined;
    authorizedUser = false;
    organization: Organization;
    ss: ShowtimeSettings;

    private _blindingSubscription: Subscription;
    private _currentGameStateSubscription: Subscription;
    private _currentGameMetadataSubscription: Subscription;
    private _allBlindtestsSubscription: Subscription;
    private _currentUserSubscription: Subscription;
    private _currentBlindtestSubscription: Subscription;
    private ssSubscription: Subscription;

    constructor(private _authService: AuthService,
                private _blindingService: OrganizationService,
                private _blindtestService: BlindtestService,
                private _gameService: GameService,
                private showtimeService: ShowtimeService,
                private _navigationService: NavigationService) {
    }

    ngOnInit() {
        this._blindingSubscription = this._blindingService.organization().subscribe(org => {
            this.organization = org;
        });

        this._currentUserSubscription = this._authService.currentUserObservable().subscribe(user => {
            this.authorizedUser = user?.hasAnyRole([
                RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_ADMIN),
                RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_OPERATOR),
                RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_MANAGER, this._blindingService.getOrganizationTagSnapshot())
            ]);
        });
        this._currentGameStateSubscription = this._gameService.currentGameControls().subscribe(gs => {
            this.hasActiveGame = !!gs;
            this.currentGameState = gs;
        });
        this._currentGameMetadataSubscription = this._gameService.currentGameMetadata().subscribe(gm => {
            this.currentGameMetadata = gm;
        });
        this._allBlindtestsSubscription = this._blindtestService.allBlindTests().subscribe(blindtests => {
            this.blindtests = blindtests || [];
        });
        this._currentBlindtestSubscription = this._blindtestService.currentBlindtest().subscribe(bt => {
            this.currentBlindtest = bt;
        });
        this.ssSubscription = this.showtimeService.currentShowtimeSettings().subscribe(ss => {
            this.hasShowtime = !!ss;
            this.ss = ss;
        });
        this._navigationService.updateNavHeader('Showtime Dashboard');

        this._blindtestService.build().subscribe(build => {
            this.build = build;
        });
    }

    ngOnDestroy() {
        unsubscribe(
            this.ssSubscription,
            this._currentGameStateSubscription,
            this._blindingSubscription,
            this._currentGameMetadataSubscription,
            this._allBlindtestsSubscription,
            this._currentUserSubscription,
            this._currentBlindtestSubscription);
    }

    deactivate() {
        this.showtimeService.deactivateShowtime().subscribe();
    }

    loading() {
        return this.hasActiveGame === undefined || this.hasShowtime === undefined;
    }
}
