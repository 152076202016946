import {Injectable} from '@angular/core';
import {GameService} from '../game/game.service';
import {Observable, ReplaySubject} from 'rxjs';
import {PlayerService} from '../player/player.service';
import {HttpClient} from '@angular/common/http';
import {ErrorHandler} from '../../handler/error-handler';
import {OrganizationService} from '../organization/organization.service';

import {Database, limitToFirst, list, orderByKey, query, ref, startAt} from '@angular/fire/database';
import {environment} from '../../../../../environments/environment';
import {map, take} from 'rxjs/operators';
import {GameControls, LeaderboardEntry, Player, Scoresheet, ServiceLeaderboardEntry} from '@frogconnexion/blinding-common';

@Injectable({
    providedIn: 'root'
})
export class ScoreService {

    private _currentGameState: GameControls;
    private _currentGameStateObservable: ReplaySubject<GameControls>;
    private _playersMap: Map<string, Player>;
    private _organization: string;

    constructor(private database: Database,
                private _blindingService: OrganizationService,
                private _gameService: GameService,
                private _playerService: PlayerService,
                private _http: HttpClient,
                private _errorHandler: ErrorHandler) {

        this._blindingService.organizationTag().subscribe(o => {
            this._organization = o;
        });
        // Current Game state observable
        this._currentGameStateObservable = new ReplaySubject<GameControls>(1);
        this._gameService.currentGameControls().subscribe(g => {
            // Replay all game-specific observables
            this._currentGameState = g;
            if (this._playersMap) {
                this._currentGameStateObservable.next(g);
            }
        });
    }

    // Asyncmethods

    giveBonus(playerId: string, bonus: number): Observable<void> {
        return this._http.post<void>(`/admin/org/${this._organization}/game/score/${playerId}/bonus/${bonus}`, null)
            .pipe(this._errorHandler.retryThreeTimesOrError());
    }

    // Observables

    fetchScores(offset: number, limit: number = 20): Observable<LeaderboardEntry[]> {
        return list(query(ref(this.database, `/${environment.globalNamespace}/games/${this._organization}/state/scores/list`),
            orderByKey(), startAt(`${offset}`), limitToFirst(limit)))
            .pipe(take(1), map((r) => {
                if (!r) {
                    return [];
                }
                return r.map(sn => sn.snapshot.val());
            }));
    }

    fetchServiceScores(offset: number, limit: number = 20): Observable<ServiceLeaderboardEntry[] | undefined> {
        return list(query(ref(this.database, `/${environment.globalNamespace}/games/${this._organization}/state/scores/listByService`),
            orderByKey(), startAt(`${offset}`), limitToFirst(limit)))
            .pipe(take(1), map((r) => {
                if (!r) {
                    return [];
                }
                return r.map(sn => sn.snapshot.val());
            }));
    }

    export(): Observable<Scoresheet[]> {
        const organization = this._blindingService.getOrganizationTagSnapshot();
        return this._http.get<Scoresheet[]>(`/admin/org/${organization}/game/score/export`)
            .pipe(this._errorHandler.retryThreeTimesOrError());
    }

}
