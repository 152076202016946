<br/>
<div class="container">
  <div class="relative">
    <div class="img-container">
      <h1 i18n="@@home-title">Showtime GoBlindtest</h1>
    </div>
  </div>
  <form (ngSubmit)="onSubmit()" [formGroup]="orgForm" class="full-width">
    <input id="name" type="text" class="form-control" autocomplete="off" spellcheck="false" formControlName="organization"
           required placeholder="Code équipe" i18n-placeholder="@@home-teamCode">
    <div class="relative">
      <div class="form-bottom">
        <div class="error alert alert-danger" [ngClass]="{hidden: !error}">
          <span i18n="@@home-errorUnknown">Une erreur s'est produite.</span>
          <br/>
          <span i18n="@@home-errorIsTeamValid">Votre code équipe est-il valide ?</span>
        </div>
        <button type="submit" [disabled]="!orgForm.valid" i18n="@@home-goToDashboard">
          On y va !
        </button>
        <br/>
        <div class="create-account">
          <a routerLink="/register" i18n="@@home-createAccount">Créer un compte</a>
        </div>
      </div>
    </div>
  </form>
</div>
