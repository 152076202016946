<div *ngIf="!isLogged; else alreadyLoggedIn" class="container">
  <mat-card class="login">
    <mat-card-content>
      <div fxLayout="column"
           fxLayoutAlign="space-between center"
           fxLayoutGap="20px">
        <mat-icon>lock</mat-icon>
        <p>Demande de création de compte <b>GoBlindtest</b> pour <b>piloter</b> l'application.</p>
        <button mat-raised-button (click)="registerFb()" id="fb">Avec Facebook</button>
        <button mat-raised-button (click)="registerGoogle()" id="google">Avec Google</button>
        <button mat-raised-button routerLink="/register/by-email" id="email">Avec ton Email</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #alreadyLoggedIn>
  <div class="container">
    <mat-card>
      <mat-card-content>
        <div fxLayout="column"
             fxLayoutAlign="space-between center"
             fxLayoutGap="20px">
          <div *ngIf="!isAdmin" style="text-align:center">
            <h2>Félicitations. La demande a bien été créée !</h2>
            Ton compte n'est pas encore activé.
            <br/>
            Contacte Loïc pour qu'il active ton compte !
            <br/><br/>
            <button mat-raised-button (click)="refreshAuth()">Recharger</button>
          </div>
          <div *ngIf="isAdmin && organizations.length > 0">
            <h2>Félicitations ! Ton compte est actif.</h2>
            <br/>
            Tu as accès aux organisations suivantes :
            <br/>
            <h3 *ngFor="let o of organizations" style="text-align:center">
              <a [routerLink]="'/org/' + o + '/dashboard'">{{o.toUpperCase()}}</a>
            </h3>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
