import {Component, Input, OnInit} from '@angular/core';
import {Organization} from '@frogconnexion/core-common';
import {Blindtest, GameControls, GameMetadata, ShowtimeSettings} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-no-blindtest-widget',
  templateUrl: './no-blindtest-widget.component.html',
  styleUrls: ['./no-blindtest-widget.component.scss']
})
export class NoBlindtestWidgetComponent implements OnInit {

  @Input() organization: Organization;
  @Input() blindtest: Blindtest;
  @Input() currentGameState: GameControls;
  @Input() currentGameMetadata: GameMetadata;
  @Input() ss: ShowtimeSettings;


  constructor() { }

  ngOnInit(): void {
  }

}
