import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ShowtimeService} from '../../showtime/showtime.service';
import {environment} from '../../../../../environments/environment';
import {ShowtimeBlobMetadata} from '../../showtime/common/models/BlobContentConfig';
import {GameControls, GameMetadata, ShowtimeSettings} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-showtime-controls',
    templateUrl: './showtime-controls.component.html',
    styleUrls: ['./showtime-controls.component.scss']
})
export class ShowtimeControlsComponent implements OnInit, OnChanges {

    @Input()
    currentGameMetadata: GameMetadata;
    @Input()
    currentGameControls: GameControls;
    @Input()
    currentShowtimeSettings: ShowtimeSettings;
    totalDurationInSeconds = 0;
    currentlyOnScreen: string;

    constructor(private showtimeService: ShowtimeService) {
    }

    ngOnInit(): void {
    }

    setShowtimeScreen(screen: string) {
        this.showtimeService.launchScreen(screen).subscribe();
    }


    changeShowtime(number: number) {
        let d = new Date();
        if (this.currentShowtimeSettings?.controls?.showtime) {
            d = new Date(this.currentShowtimeSettings.controls.showtime);
        }
        if (new Date().getTime() > d.getTime()) {
            d = new Date();
        }
        d.setSeconds(d.getSeconds() + number);
        if (number === 0) {
            d = new Date();
            d.setSeconds(d.getSeconds() + 11);
        }
        this.showtimeService.setTimeOfShow(d).subscribe();
    }

    get countdown(): [number, number] {
        if (!(this.currentShowtimeSettings?.controls?.showtime)) {
            return [0, 0];
        }
        const secsRemaining = new Date(this.currentShowtimeSettings.controls.showtime).getTime() / 1000 - new Date().getTime() / 1000;
        return [Math.max(0, Math.floor(secsRemaining / 60)), Math.max(0, Math.floor(secsRemaining % 60))];
    }

    ngOnChanges(changes: SimpleChanges): void {
        const lang = this.currentGameMetadata?.lang;
        this.currentlyOnScreen = this.currentShowtimeSettings?.controls?.currentlyOnScreen;
        if (!lang || !this.currentlyOnScreen) {
            return;
        }
        if (this.currentlyOnScreen === 'countdown' && (this.countdown[1] > 0 || this.countdown[0] > 0)) {
            this.totalDurationInSeconds = this.countdown[0] * 60 + this.countdown[1];
        } else {
            this.totalDurationInSeconds = this.findMediaMetadata(this.currentlyOnScreen, lang)?.duration || 0;
        }
    }

    get backtrackMuted() {
        return this.currentShowtimeSettings?.controls?.backtrackMuted;
    }

    get isSoundActive() {
        switch (this.currentlyOnScreen) {
            case 'feed':
            case 'join-the-game':
            case 'leaderboard':
                return true;
            default:
                return false;
        }
    }

    setBacktrackMuted(muted: boolean) {
        this.showtimeService.setBacktrackMuted(muted).subscribe();
    }

    setBacktrackVolume(volume: number) {
        const attenuationDb = (100 - volume);
        const ratio = 1 / Math.pow(10, (attenuationDb / 30));
        this.showtimeService.setBacktrackVolume(Math.max(0, ratio)).subscribe();
    }

    private findMediaMetadata(key: string, lang: string): ShowtimeBlobMetadata {
        return environment.showtime.media.find(m => m.key === key && m.type === 'video'
            && (m.lang === lang || m.lang === '*')) as ShowtimeBlobMetadata;
    }
}
