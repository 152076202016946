<mat-card>
    <mat-card-header>
        <img mat-card-avatar src="/assets/imgs/logo.png" alt='BlindTest Avatar'/>
        <mat-card-title i18n="@@dashboardLoading-title">...</mat-card-title>
        <mat-card-subtitle i18n="@@dashboardLoading-loading">
            Chargement en cours
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-spinner diameter="60"></mat-spinner>
    </mat-card-content>
    <mat-card-actions>
        <app-access-buttons [ss]="" [loading]="true"></app-access-buttons>
    </mat-card-actions>
</mat-card>
