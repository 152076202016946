<mat-card class="current">
  <mat-card-header>
    <img mat-card-avatar [src]="currentGameMetadata?.branding ? currentGameMetadata.branding.logoUrl : '/assets/imgs/logo.png'" alt='BlindTest Avatar'/>
    <mat-card-title>{{currentGameMetadata.name}}</mat-card-title>
    <mat-card-subtitle *ngIf="ss">
      <ng-container i18n="@@dashboardGame-lang">Langue Showtime :</ng-container> &nbsp;<b>{{ss?.metadata?.lang}}</b>
      <br/>
      <span *ngIf="ss?.controls?.showtime"><ng-container i18n="@@dashboardGame-timeOfShow">Heure programmée :</ng-container> <b>{{ss?.controls?.showtime | date:'dd/MM hh:mm'}}</b></span>
    </mat-card-subtitle>
    <mat-card-subtitle *ngIf="getSetCount() === 1" i18n="@@dashboardGame-oneRoundSongCount">1 manche  - {{getItemsCount()}} chansons</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="blindtest.sets">
    <div><b i18n="@@dashboardGame-pin">Pin :</b>&nbsp;<span class="pin">{{currentGameMetadata?.pin | uppercase}}</span></div>
    <ng-container *ngFor="let set of blindtest.sets; let i = index">
      <div class="set-lbl" i18n="@@dashboardGame-roundNumber">Manche N°{{i+1}}</div>
      <div *ngFor="let section of set.sections">
        <span class="section-lbl">{{section.label}}</span>
        <span class="section-size" i18n="@@dashboardGame-songCount">{{section.endIndex - section.startIndex}} chansons</span>
      </div>
    </ng-container>
  </mat-card-content>
  <mat-card-actions>
    <app-access-buttons [ss]="ss"></app-access-buttons>
  </mat-card-actions>
  <mat-card-footer *ngIf='currentGameState'>
    <mat-chip-list>
      <mat-chip color="primary" *ngIf="currentGameState.blindtestControl.status === 0" selected i18n="@@dashboardGame-ready">Prêt</mat-chip>
      <mat-chip color="accent" *ngIf='currentGameState.blindtestControl.isRunning()' selected i18n="@@dashboardGame-ongoing">En cours</mat-chip>
      <mat-chip *ngIf='currentGameState.blindtestControl.status === 3' i18n="@@dashboardGame-finished">Terminé</mat-chip>
    </mat-chip-list>
  </mat-card-footer>
  <div [hidden]='!error' class='alert alert-danger'>
    {{ error }}
  </div>
</mat-card>
