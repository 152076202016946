import {HttpErrorResponse} from '@angular/common/http';
import {concat, MonoTypeOperatorFunction, Observable, throwError, timer} from 'rxjs';
import {LoggerService} from '../services/logger/logger.service';

import {MatSnackBar} from '@angular/material/snack-bar';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../auth/service/auth.service';
import {catchError, delay, finalize, mergeMap, retryWhen, take} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ErrorHandler {

    constructor(private logger: LoggerService,
                private router: Router,
                private authService: AuthService,
                private snackBar: MatSnackBar) {
        this.handleErrorAndLog = this.handleErrorAndLog.bind(this);
        this.handleErrorNoLog = this.handleErrorNoLog.bind(this);
    }

    genericRetryStrategy = ({
                                maxRetryAttempts = 3,
                                scalingDuration = 300,
                                excludedStatusCodes = []
                            }: {
        maxRetryAttempts?: number,
        scalingDuration?: number,
        excludedStatusCodes?: number[]
    } = {}) => (attempts: Observable<any>) => {
        return attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;
                // if maximum number of retries have been met
                // or response is a status code we don't wish to retry, throw error
                if (
                    retryAttempt > maxRetryAttempts ||
                    excludedStatusCodes.find(e => e === error.status)
                ) {
                    return throwError(error);
                }
                this.logger.warn(
                    `Attempt ${retryAttempt}: retrying in ${retryAttempt *
                    scalingDuration}ms`
                );
                // retry after 1s, 2s, etc...
                return timer(retryAttempt * scalingDuration);
            })
        );
    }

    retryThreeTimesOrError<T>(): MonoTypeOperatorFunction<T> {
        return (source: Observable<T>) => source.pipe(
            catchError(this.handleErrorNoLog),
            retryWhen(this.genericRetryStrategy()),
            catchError(this.handleErrorAndLog)
        );
    }

    handleErrorNoLog(error: HttpErrorResponse) {
        return this.handleErrorGeneric(error, false);
    }

    handleErrorAndLog(error: HttpErrorResponse) {
        return this.handleErrorGeneric(error, true);
    }

    handleErrorGeneric(error: HttpErrorResponse, log = false) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            this.logger.warn('An error occurred:', error?.error?.message || error?.message || error);
            if (log) {
                this.snackBar.open(`An error occurred.\r\nCheck Network\r\n${error?.error?.message || error?.message || error}`, null, {duration: 4000});
            }
        } else {
            // The backend returned an unsuccessful response code.
            this.logger.warn(`Backend returned code ${error.status}`, `body was`, error.error);
            // The response body may contain clues as to what went wrong,
            if (error && error.status && error.status === 401) {
                // This is an auth issue. Redirect to login
                if (log) {
                    const callback = this.snackBar.open('Are you authenticated?', 'Me reconnecter', {duration: 4000});
                    callback.onAction().subscribe(() => {
                        this.authService.signOut().then(() => {
                            this.router.navigate(['/']);
                        });
                    });
                }
                this.authService.refreshFromAuthState();
            } else if (log) {
                this.logger.warn(error?.error?.message || error?.message || error);
                if (environment.production) {
                    this.snackBar.open(`A Server Error occurred. Please try again`, null, {duration: 4000});
                } else {
                    this.snackBar.open(`A Server Error occurred.\r\n${error?.error?.message || error?.message || error}\``, null, {duration: 4000});
                }
            }
        }
        return throwError(() => error);
    }
}
