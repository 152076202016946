<div class="container">
  <mat-card>
    <form (ngSubmit)="onSubmit()" #emailForm="ngForm" class="full-width">
      <h2 style="text-align: center;">Entre tes identifiants The Bold Heads</h2>
      <mat-form-field class="full-width">
        <input matInput placeholder="Email" [formControl]="emailFormControl" [(ngModel)]="email" [errorStateMatcher]="matcher"/>
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Merci d'entrer un email valide
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Email <strong>requis</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput [formControl]="passwordFormControl" placeholder="Mot de passe" [(ngModel)]="password"
               [type]="!hide ? 'password' : 'text'"/>
        <mat-icon matSuffix (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        <mat-error *ngIf="passwordFormControl.hasError('required')">
          Mot de passe <strong>requis</strong>
        </mat-error>
      </mat-form-field>
      <br/>
      <div class="centered">
        <button mat-raised-button color="primary" type="submit" [disabled]="!emailForm.valid">On y va!</button>
      </div>
      <div [hidden]="!error" class="alert alert-danger">
        <br/>
        Une erreur s'est produite.<br/>Es-tu sur d'avoir créé un compte?
      </div>
      <mat-card-footer class="bottomPad">
        <div fxFlex fxLayoutAlign="space-around center">
          <button mat-raised-button routerLink="/signup" class="alc">Je n'ai pas de compte</button>
        </div>
      </mat-card-footer>
    </form>
  </mat-card>
</div>
