<ng-container *ngIf="!loading()">
    <div class="content" *ngIf="hasActiveGame">
        <div *ngFor='let bt of blindtests'>
            <app-blindtest-widget [blindtest]="bt" *ngIf="bt?.id === currentGameMetadata?.blindtestId" [ss]="ss"
                                  [currentGameState]='currentGameState' [currentGameMetadata]="currentGameMetadata"
                                  [organization]="organization"></app-blindtest-widget>
        </div>
    </div>
    <div class="content" *ngIf="!hasActiveGame">
        <app-no-blindtest-widget [ss]="ss" [currentGameState]='currentGameState'
                                 [currentGameMetadata]="currentGameMetadata"
                                 [organization]="organization"></app-no-blindtest-widget>
    </div>
</ng-container>

<div class="content" *ngIf="loading()">
    <app-loading-widget></app-loading-widget>
</div>

