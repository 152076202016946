export enum MediaPlayState {
    START = 'start',
    PLAYING = 'playing',
    PAUSE = 'pause',
    PAUSED = 'paused',
    RESUME = 'resume',
    FORWARD = 'forward',
    BACKWARD = 'backward',
    ENDING = 'ending',
    END = 'end',
    STOP_IMMEDIATELY = 'stop-immediately',
    NONE = 'none',
}
