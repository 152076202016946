import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';

import {AuthService} from '../auth.service';
import {Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {LoggerService} from '../../../services/logger/logger.service';
import {Claims, RoleClaim} from '@frogconnexion/core-common';

@Injectable()
export class AdminAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private logger: LoggerService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const organization = route.paramMap.get('organization');
    return this.authService.currentUserObservable().pipe(mergeMap(user => {
      if (!user || !user.authenticated) {
        this.logger.debug('Auth Guard Failed. Authenticating first.');
        this.router.navigate([`/org/${organization}/login`]);
        return;
      }
      if (user.anonymous) {
        this.logger.debug('Auth Guard Failed. Anonymous user.');
        this.router.navigate([`/org/${organization}/login`]);
        return of(false);
      }

      const authorizedUser = user.hasAnyRole([
        RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_ADMIN),
        RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_OPERATOR),
        RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_MANAGER, organization)
      ]);
      if (!authorizedUser) {
        this.logger.debug('Auth Guard Failed. User is not admin.');
        this.router.navigate([`/org/${organization}/unauthorized`]);
        return of(false);
      }
      this.logger.debug('Auth Guard Success.');
      return of(true);
    }));
  }
}
